<template>
<div>

<!-- EDICION Y CREACION DE REQUERIMIENTOS EN VENTANA MODAL  -->
    <div v-if="dialog">
        <v-dialog class="elevation-0" v-model="dialog" width="100%" persistent transition="dialog-bottom-transition">
            <lgl-complaints-edit :value="Requirement.CpnID" @close="dialog = false"></lgl-complaints-edit>
        </v-dialog>
    </div>
    <v-row>
        <v-col style="padding-bottom: 0px">
            <s-crud group-by="TypeComplaintName" noFull height="auto" ref="grid" :view="selected.length > 0 ? true : false" @rowSelected="rowSelected($event)" search-input="" add-no-function :filter="filter" @add="add()" :title="'Casos Legales'" add @edit="edit()" :remove="selected.length > 0 ? true : false" :config="config"  @doubleClick="doubleClick($event)">
                <template v-slot:filter>
                    <v-container>
                        <v-container>
                            <v-row justify="center">

                                <v-col lg="3" md="3" cols="6" style="padding-top: 0px; padding-bottom: 0px">
                                    <s-select-definition clearable :def="1194"  label="Tipo" v-model="filter.TypeComplaint" />
                                </v-col>

                                <v-col lg="3" md="3" cols="6" class="s-col-form">
                                    <s-select-definition clearable label="Estados" noDefault v-model="filter.CpnStatus" :def="1197" />
                                </v-col>
                                <v-col lg="3" md="3" cols="6" class="s-col-form">
                                    <s-date v-model="filter.BeginDate" clearable label="Fecha Inicio" />
                                </v-col>
                                <v-col lg="3" md="3" cols="6" class="s-col-form">
                                    <s-date v-model="filter.EndDate" clearable label="Fecha Fin" />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-container>
                </template>
                 <template v-slot:CpnStatusName="{ row }">
                    <v-chip x-small :color="row.CpnStatusColor">
                        {{ row.CpnStatusName }}<span>
                        </span>
                    </v-chip>

                </template>
                <template v-slot:CpnSubStatusName="{ row }">
                    <v-chip x-small v-if="row.CpnSubStatusName !== null">
                        {{ row.CpnSubStatusName }}<span>
                        </span>
                    </v-chip>

                </template>

                

            </s-crud>
        </v-col>
    </v-row>

</div>
</template>

<script>
//Services
import _sComplaints from "@/services/Legal/LglComplaintsService";


//Components
import LglComplaintsEdit from "@/views/Legal/Complaints/LglComplaintsEdit";
export default {
    name: "",
    components: {
        LglComplaintsEdit
    },
    props: {},
    data() {
        return {
            filter: {
                BeginDate: null,
                EndDate: null,
                CpnStatus: null,
                TypeComplaint:null
            },
            config: {
                model: {
                    CpnID: "ID",
                    CpnDate:"date",
                    CpnStatusName:"string",
                    CpnSubStatusName:"string"
                },
                service: _sComplaints,
                headers: [/* {
                        text: "ID",
                        value: "CpnID",
                        sortable: false,
                        width: 40
                    }, */
                    /* {
                        text: "N°",
                        value: "CpnDocumentNumber",
                        sortable: false,
                        width: 100,

                    }, */

                    {
                        text: "Tipo",
                        value: "TypeComplaintName",
                        sortable: false,

                    },

                    {
                        text: "Fecha I.",
                        value: "CpnDate",
                        sortable: false,
                        width: 110
                    },
                    {
                        text: "Nro. Expediente",
                        value: "CpnFileNumber",
                        sortable: false,
                        width: 120
                    },
                    {
                        text: "Datos del Demandante(s)/Denunciante(s)",
                        value: "CpnNameComplaint",
                        sortable: false,

                    },
                    {
                        text: "Datos del Demandado(s)/Denunciado(s)",
                        value: "CpnNamePersonaDenounced",
                        sortable: false,

                    },
                    {
                        text: "Juzgado",
                        value: "TypeCourtName",
                        sortable: false,
                        width: 200

                    },
                    {
                        text: "Estudio a Cargo",
                        value: "TypeStudioInChargeName",
                        sortable: false,
                        width: 180

                    },
                    {
                        text: "Estado",
                        value: "CpnStatusName",
                        sortable: false,

                    },
                    {
                        text: "Sub Estado",
                        value: "CpnSubStatusName",
                        sortable: false,

                    },
                    {
                        text: "Responsable a Cargo",
                        value: "TypeResponsibleName",
                        sortable: false,
                        width: 120

                    },
                    
                    
                    
                    
                    
                    
                    
                    
                    
                    
                    
                    /* {
                        text: "Creado Por",
                        value: "UsrName",
                        sortable: false,

                    }, */
                    
                    

                ],
            },
            selected: [],
            CpnID: null,
            Requirement: {},
            dialog:false

        }
    },
    created() {

    },

    watch: {
        dialog() {
            this.$refs.grid.refresh();
        },

    },

    methods: {
        //seleccionar registro por defecto
        rowSelected(item) {
            this.selected = item;
            if (item != null) {
                if (item.length > 0) {
                    if (item[0].CpnID != null)
                        this.CpnID = item[0].CpnID
                }
            }

            console.log(this.selected)
        },

        //Nuevo Registro
        add() {

            this.Requirement.CpnID = null;
            this.dialog = true;
        },

        //Editar Requerimiento
        edit() {
            this.Requirement = this.selected.length > 0 ? this.selected[0] : null;
            this.dialog = true;
        },

        doubleClick(item) {
            this.Requirement = item !== null ? item : null;
            console.log(item)

            this.dialog = true;
        },
    },

};
</script>
