<template>
<div>

    <modal-loading :processing="processing" :messageProcessing="messageProcessing"></modal-loading>

    <v-dialog v-if="dialog" v-model="dialog" width="500" persistent>
        <v-container>
            <v-card>
                <v-row style="margin:auto">
                    <v-col cols="12">
                        <s-select-definition return-object v-model="objPro" :def="1197" :dgr="52" label="Fin de Proceso"></s-select-definition>
                    </v-col>

                    <v-col cols="12" v-if="objPro !== null ? objPro.DedValue==2: null" style="margin-top:-20px">
                        <s-select-definition v-model="requirement.CpnSubStatus" :def="1205" label="Sub Estado"></s-select-definition>
                    </v-col>

                    <v-col cols="12" style="margin-top:-20px">
                        <s-textarea label="Detalle del resultado" v-model="requirement.CpnStatusDetail"></s-textarea>
                    </v-col>
                    <v-col cols="12" style="margin-top:-20px">
                        <v-btn text color="info" @click="closeProccess()">Grabar</v-btn>
                        <v-btn text color="red" @click="dialog=false">Close</v-btn>
                    </v-col>
                </v-row>
            </v-card>

        </v-container>

    </v-dialog>

    <v-card class="mt-2">

        <s-toolbar style="font-family: Calibri;" dark close @close="$emit('close')" @save="save()" :save="requirement.CpnStatus == 4 || requirement.CpnID == null" :label="'Caso Legal  ' + (requirement.CpnID == null ? '' : requirement.CpnDocumentNumber + ' - ' + requirement.UsrName)">
        </s-toolbar>

        <v-divider></v-divider>

        <v-card outlined>
            <v-col>
                <v-row>
                    <v-col lg="5" md="5" cols="12">
                        <v-card elevation="0" height="auto">
                            <v-row>
                                <v-col lg="6" md="6" cols="6" style="padding-top: 0px; padding-bottom: 16px">
                                    <s-text disabled label="Usuario" :value="requirement.CpnID == null ? user.NtpFullName : requirement.UsrName"></s-text>
                                </v-col>
                                <v-col cols="6" style="padding-top: 0px; padding-bottom: 0px">
                                    <c-select-area v-if="isEditable" disabled label="Área" :text="user.CtrContract.AreName" v-model="requirement.AreID" />
                                    <s-text label="Área" :disabled="!isEditable" v-if="!isEditable" v-model="requirement.AreName"></s-text>
                                </v-col>
                            </v-row>

                            <v-divider style="margin-top:2px; margin-bottom: 0px" horizontal></v-divider>
                            <v-divider style="margin-top:5px; margin-bottom: 0px" horizontal></v-divider>

                            <v-row justify="center" class="mt-0">
                                <v-col cols="4" style="padding-top: 0px; padding-bottom: 0px">
                                    <s-select-definition :def="1194" :readonly="!isEditable" label="Tipo" v-model="requirement.TypeComplaint" />
                                </v-col>
                                <v-col cols="4" style="padding-top: 5px; padding-bottom: 0px">
                                    <s-select-definition add full autocomplete :readonly="!isEditable" :def="1196" label="Estudio a Cargo" v-model="requirement.TypeStudioInCharge" />
                                </v-col>
                                <v-col cols="4" style="padding-top: 7px; padding-bottom: 0px">
                                    <s-select-definition add :def="1198" :readonly="!isEditable" label="Reponsable de Caso" v-model="requirement.TypeResponsible" />
                                </v-col>
                            </v-row>

                            <v-divider style="margin-top:20px; margin-bottom: 0px" horizontal></v-divider>

                            <!-- <v-divider style="margin-top:2px; margin-bottom: 20px" horizontal></v-divider>
                            <v-divider style="margin-top:5px; margin-bottom: 10px" horizontal></v-divider> -->

                            <v-row justify="center" class="mt-1 mb-4" v-if="!isEditablePnl">
                                <v-col cols="4" style="padding-top: 10px; padding-bottom: 0px;">
                                    <!--  <v-row style="margin:auto"> -->
                                    <!-- <v-col cols="12"> -->
                                    <!-- <v-row> -->
                                    <v-row>
                                        <v-col cols="12">
                                            <v-card style="border: ridge;">

                                                <v-col cols="12">
                                                    <h3>T. Pretencion ($):</h3>
                                                </v-col>
                                                <v-col cols="12" style="margin-top:-25px">
                                                    <h3>
                                                        {{
                            requirement.ClaimTotal >= 1000
                              ? requirement.ClaimTotal.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : requirement.ClaimTotal > 0 ? requirement.ClaimTotal.toFixed(2) : 0.0
                          }}
                                                    </h3>
                                                </v-col>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" style="margin-top:-20px">
                                            <v-card style="border: ridge;">

                                                <v-col cols="12">
                                                    <h3>M/T/C ($):</h3>
                                                </v-col>
                                                <v-col cols="12" style="margin-top:-25px">
                                                    <h3>
                                                        {{
                            requirement.BillsTotal >= 1000
                              ? requirement.BillsTotal.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : requirement.BillsTotal > 0 ? requirement.BillsTotal.toFixed(2) : 0.0
                          }}</h3>

                                                </v-col>
                                            </v-card>
                                        </v-col>
                                        
                                        <v-col cols="12" style="margin-top:-20px">
                                            <v-card style="border: ridge;">
                                                <v-col cols="12">
                                                    <h3>T. Resumen ($):</h3>
                                                </v-col>
                                                <v-col cols="12" style="margin-top:-25px">
                                                    <h3>
                                                        {{
                            requirement.TotalResumen >= 1000
                              ? requirement.TotalResumen.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : requirement.TotalResumen.toFixed(2)
                          }}
                                                    </h3>
                                                </v-col>
                                            </v-card>
                                        </v-col>

                                    </v-row>

                                </v-col>
                                <v-divider style="margin-top:2px; margin-bottom: 0px" vertical></v-divider>
                                <v-col cols="4" style="padding-top: 10px; padding-bottom: 0px;" align="center">
                                    <h2>Estado Final</h2>

                                    <v-img v-if="requirement.CpnStatus == 1" src="../../../assets/ganamos.png" width="150" class="bg-grey-lighten-2" style="background-size: contain;"></v-img>
                                    <v-img v-if="requirement.CpnStatus == 2" src="../../../assets/perdimos.png" width="150" class="bg-grey-lighten-2" style="background-size: contain;"></v-img>
                                    <v-img v-if="requirement.CpnStatus == 3" src="../../../assets/improcedente.png" width="150" class="bg-grey-lighten-2" style="background-size: contain;"></v-img>
                                    <v-img v-if="requirement.CpnStatus == 4" src="../../../assets/enproceso.png" width="150" class="bg-grey-lighten-2" style="background-size: contain;"></v-img>
                                    <v-img v-if="requirement.CpnStatus == 5" src="../../../assets/mutuoacuerdo.png" width="150" class="bg-grey-lighten-2" style="background-size: contain;"></v-img>

                                    <!-- <v-img :src="requirement.CpnStatus == 2 ? '../../../assets/ganamos.png': '../../../assets/ganamos.png'" width="150" class="bg-grey-lighten-2" style="background-size: contain;"></v-img> -->
                                    <h2>{{requirement.CpnStatusName}}</h2>
                                    <h1 v-if="requirement.CpnSubStatusName !== null" style="transform: scale(1.0);">{{requirement.CpnSubStatusName}}</h1>
                                    <v-card style="border: ridge;">
                                        <h4>{{requirement.CpnStatusDetail}}</h4>
                                    </v-card>
                                </v-col>
                                <v-divider style="margin-top:2px; margin-bottom: 0px" vertical></v-divider>
                                <v-col cols="4" style="padding-top: 10px; padding-bottom: 0px;" align="center">
                                    <ul style="list-style-type: disclosure-closed;">
                                        <v-divider></v-divider>
                                        <li v-if="requirement.CpnStatus == 4">
                                            <!-- C:\Users\irvin.panta\Documents\Visual Studio 2019\Projects\SunExpertGit\vue\src\assets -->
                                            <v-img src="../../../assets/mazolegal.png" width="40" class="bg-grey-lighten-2" style="background-size: contain;"></v-img>
                                            <v-btn text elevation="0" small class="capitalize" @click="dialog=true">Cerrar Caso</v-btn>
                                        </li>
                                        <v-divider></v-divider>
                                        <v-divider></v-divider>
                                        <li>
                                            <v-btn text elevation="0" small class="capitalize">Descargar Caso</v-btn>
                                        </li>
                                        <v-divider></v-divider>
                                    </ul>

                                    <v-row style="margin:auto" class="mt-4">
                                        <v-col cols="12" style="margin-top:-20px">
                                            <v-card style="border: ridge;">
                                                <v-col cols="12">
                                                    <h3>P. Pérdida:</h3>
                                                </v-col>
                                                <v-col cols="12" style="margin-top:-25px">
                                                    <h3>
                                                        {{
                            requirement.CpnOddsOfLoss
                          }}
                                                    </h3>
                                                </v-col>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" style="margin-top:-20px">
                                            <v-card style="border: ridge;">
                                                <v-col cols="12">
                                                    <h3>P. Contable:</h3>
                                                </v-col>
                                                <v-col cols="12" style="margin-top:-25px">

                                                    <h3>
                                                        {{requirement.TypeCurrencyNameProvision}} {{
                            requirement.CpnTotalAccountingProvision >= 1000
                              ? requirement.CpnTotalAccountingProvision.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : requirement.CpnTotalAccountingProvision.toFixed(2)
                          }}
                                                    </h3>
                                                </v-col>
                                            </v-card>
                                        </v-col>

                                        <v-col cols="12" style="margin-top:-20px">
                                            <v-card style="border: ridge;">

                                                <v-col cols="12">
                                                    <h3>H. Legales ($):</h3>
                                                </v-col>
                                                <v-col cols="12" style="margin-top:-25px">
                                                    <h3>
                                                        {{
                            requirement.BillsTotalHL >= 1000
                              ? requirement.BillsTotalHL.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : requirement.BillsTotalHL > 0 ? requirement.BillsTotalHL.toFixed(2) : 0.0
                          }}</h3>

                                                </v-col>
                                            </v-card>
                                        </v-col>
                                    </v-row>

                                    <!-- <v-img src="../../../assets/mazolegal.png" width="130" class="bg-grey-lighten-2" style="background-size: contain;"></v-img> -->

                                </v-col>

                                <v-col lg="12" cols="12" class="s-col-form mt-0" v-if="!isEditablePnl">
                                    <!--  <v-row> -->
                                    <v-col cols="12">
                                        <v-divider></v-divider>
                                        <label>
                                            <h3>Adjuntos.<v-btn v-if="requirement.CpnStatus == 4" text icon class="ml-4" elevation="0" color="success" x-small style="text-transform: initial;" @click="uploadFile()">
                                                    <v-icon> mdi-arrow-up</v-icon> Subir
                                                </v-btn>
                                            </h3>
                                        </label>

                                        <v-divider></v-divider>
                                    </v-col>

                                    <v-row style="margin:auto;margin-top:-15px">
                                        <v-col cols="6" v-if="isEditable">
                                            <s-text :outlined="false" label="Descripcion" v-model="descriptionAttach" ref="descriptionAttach"></s-text>
                                        </v-col>
                                        <v-col lg="6" cols="6" class="s-col-text" v-if="isEditable">

                                            <v-row>
                                                <v-col cols="12" v-for="(item, index) in itemsAttach" :key="item.RqdID">
                                                    <v-file-input show-size counter :ref="'attach'+item.Id" :accept="item.RqdAccept" :label="item.RqdDescription" v-model="item.RqdAttach">
                                                    </v-file-input>
                                                </v-col>
                                            </v-row>

                                        </v-col>

                                        <v-col cols="12" class="s-col-text">
                                            <v-card outlined>
                                                <v-data-table class="elevation-0 scroll table table-hover table-condensed" :items-per-page="-1" disable-sort hide-default-footer dense :headers="headersAttach" :items="requirement.listAttach">
                                                    <template v-slot:item.RqdAttach="{ item }">
                                                        <v-icon color="green" @click="openFile(item.Attach)">mdi-download</v-icon>
                                                        {{item.RqdAttach}}
                                                    </template>
                                                    <template v-if="requirement.CpnStatus == 4" v-slot:item.delete="{ item }">
                                                        <a v-if="item.UsrCreateID == userID" style="color:red" @click="deleteOP(item, 1)">Eliminar</a>
                                                    </template>

                                                </v-data-table>
                                            </v-card>
                                        </v-col>
                                    </v-row>

                                    <!-- </v-row> -->

                                </v-col>
                            </v-row>

                            <v-divider style="margin-top:2px; margin-bottom: 0px" horizontal></v-divider>
                            <v-divider style="margin-top:5px; margin-bottom: 0px" horizontal></v-divider>
                            <v-divider style="margin-top:10px; margin-bottom: 0px" horizontal></v-divider>

                        </v-card>
                    </v-col>

                    <v-divider vertical></v-divider>

                    <v-col lg="7" md="7" cols="12">
                        <v-row justify="center">

                            <v-col cols="4" class="s-col-form" style="padding-top: 0px; padding-bottom: 0px;margin-top:-0px">
                                <s-date label="Fecha Estimada termino de Proceso" :value="requirement.CpnEstimatedEndDate" v-model="requirement.CpnEstimatedEndDate"></s-date>
                            </v-col>
                            <v-col cols="4" style="padding-top: 0px; padding-bottom: 0px">
                                <s-date disabled label="Fecha Solicitud" :value="requirement.SecCreate" />
                            </v-col>

                            <v-col cols="4" style="padding-top: 0px; padding-bottom: 0px">
                                <s-select-definition :dgr="51" :def="1197" label="Estado" :disabled="!isEditable" v-model="requirement.CpnStatus" />
                            </v-col>

                        </v-row>

                        <v-divider class="mt-3"></v-divider>

                        <v-divider style="margin-top:3px; margin-bottom: 0px" horizontal></v-divider>

                        <v-row>

                            <v-col cols="6">
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                    <label>
                                        <h3>{{requirement.TypeComplaint == 2 ? 'Datos del Demandante(s)' : 'Datos del Denunciante(s)'}} </h3>
                                    </label>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" class="s-col-form" style="margin-top:-12px">
                                    <s-textarea label="Nombre(s)" v-model="requirement.CpnNameComplaint" ref="CpnNameComplaint"></s-textarea>
                                </v-col>
                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col cols="6">
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                    <label>
                                        <h3>{{requirement.TypeComplaint == 2 ? 'Datos del Demandado(s)' : 'Datos del Denunciado(s)'}} </h3>
                                    </label>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" class="s-col-form" style="margin-top:-12px">
                                    <s-textarea label="Denunciado(s)" v-model="requirement.CpnNamePersonaDenounced" ref="CpnNamePersonaDenounced"></s-textarea>
                                </v-col>
                            </v-col>

                        </v-row>

                        <v-divider style="margin-top:3px; margin-bottom: 0px" horizontal></v-divider>

                        <v-row v-if="!isEditablePnl">

                            <v-col cols="12">
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                    <label>
                                        <h3>Historial de Proceso.

                                            <v-btn icon rounded elevation="0" @click="showHP = !showHP;showDG=false;showPE=false;showPR=false" :color="'info'" x-small>
                                                <v-icon>{{ showHP ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                            </v-btn>
                                        </h3>
                                    </label>

                                    <v-divider></v-divider>
                                </v-col>

                                <v-row style="margin:auto;margin-top:-15px">

                                    <v-expand-transition>
                                        <div v-show="showHP">
                                            <v-btn text class="capitalize" v-if="requirement.CpnStatus == 4" elevation="0" x-small color="info" @click="saveRecord()">
                                                <v-icon>mdi-content-save</v-icon>Guardar
                                            </v-btn>
                                            <v-row style="margin:auto" v-if="isEditable">

                                                <v-col cols="6" class="s-col-text">
                                                    <s-select-definition :def="1199" label="Estado" v-model="itemRecord.TypeState" add></s-select-definition>
                                                </v-col>
                                                <v-col cols="6" class="s-col-text">
                                                    <s-text label="Gestion por Realizar" v-model="itemRecord.CprTasktoPerform" ref="CprTasktoPerform"></s-text>
                                                </v-col>
                                                <v-col cols="12" class="s-col-text">
                                                    <s-text label="Comentario" v-model="itemRecord.CprComments"></s-text>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-expand-transition>

                                    <v-col cols="12" class="s-col-text">
                                        <v-card outlined>
                                            <v-data-table class="elevation-0 scroll table table-hover table-condensed" :items-per-page="-1" disable-sort hide-default-footer dense :headers="headersRecord" :items="requirement.listRecord">
                                                <template v-slot:item.SecCreate="{ item }">
                                                    {{$moment(item.SecCreate).format($const.FormatDateTimeDB)}}
                                                </template>
                                                <template v-if="requirement.CpnStatus == 4" v-slot:item.delete="{ item }">
                                                    <v-icon v-if="item.UsrCreateID == userID" style="font-size:16px; color:red" @click="deleteOP(item, 2)"> mdi-minus-circle-outline</v-icon>
                                                    <!-- <a v-if="item.UsrCreateID == userID" style="color:red" @click="deleteOP(item, 2)">Eliminar</a> -->
                                                </template>
                                            </v-data-table>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <v-divider style="margin-top:0px; margin-bottom: 0px" horizontal></v-divider>
                                <!-- <v-divider style="margin-top:0px; margin-bottom: 0px" horizontal></v-divider> -->

                            </v-col>

                            <v-col cols="12" style="margin-top:-25px">
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                    <label>
                                        <h3>Provisiones.
                                            <v-btn icon rounded elevation="0" @click="showPR = !showPR;showDG=false;showPE=false;showHP=false" :color="'info'" x-small>
                                                <v-icon>{{ showPR ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                            </v-btn>
                                        </h3>
                                    </label>

                                    <v-divider></v-divider>
                                </v-col>

                                <v-row style="margin:auto;margin-top:-15px">

                                    <v-expand-transition>
                                        <div v-show="showPR">
                                            <v-btn text class="capitalize" v-if="requirement.CpnStatus == 4" x-small color="info" @click="saveProvition()">
                                                <v-icon>mdi-content-save</v-icon>Grabar
                                            </v-btn>
                                            <v-row style="margin:auto" v-if="isEditable">
                                                <v-col cols="6" class="s-col-text">
                                                    <s-select-definition :def="1203" label="Probabilidades de Pérdida" v-model="itemProvition.TypeCategory" add></s-select-definition>
                                                </v-col>
                                                <v-col cols="6" class="s-col-text">

                                                    <s-select-definition :def="1129" label="Provision Contable(Moneda)" v-model="itemProvition.TypeCurrency" add></s-select-definition>

                                                </v-col>
                                                <v-col cols="12" class="s-col-text">
                                                    <s-text decimal label="Provision Contable(Monto)" v-model="itemProvition.PvtTotal" ref="PvtTotal"></s-text>
                                                </v-col>
                                                <v-col cols="12" class="s-col-text">
                                                    <s-textarea label="Detalle" v-model="itemProvition.PvtComments"></s-textarea>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-expand-transition>

                                    <v-col cols="12" class="s-col-text">
                                        <v-card outlined>
                                            <v-data-table class="elevation-0 scroll table table-hover table-condensed" :items-per-page="-1" disable-sort hide-default-footer dense :headers="headersProvition" :items="requirement.listProvition">
                                                <template v-slot:item.SecCreate="{ item }">
                                                    <!-- {{item.SecCreate}} -->
                                                    {{$moment(item.SecCreate).format($const.FormatDateTimeDB)}}
                                                </template>
                                                <template v-if="requirement.CpnStatus == 4" v-slot:item.delete="{ item }">
                                                    <!-- <a v-if="item.UsrCreateID == userID" style="color:red" @click="deleteOP(item, 5)">Eliminar</a> -->
                                                    <v-icon v-if="item.UsrCreateID == userID" style="font-size:16px; color:red" @click="deleteOP(item, 5)"> mdi-minus-circle-outline</v-icon>
                                                </template>
                                            </v-data-table>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <v-divider style="margin-top:0px; margin-bottom: 0px" horizontal></v-divider>
                                <!-- <v-divider style="margin-top:0px; margin-bottom: 0px" horizontal></v-divider> -->

                            </v-col>

                            <v-col cols="12" style="margin-top:-25px">
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                    <label>
                                        <h3>Pretencion Ecónomica.
                                            <v-btn icon rounded elevation="0" @click="showPE = !showPE;showDG=false;showHP=false;showPR=false" :color="'info'" x-small>
                                                <v-icon>{{ showPE ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                            </v-btn>
                                        </h3>
                                        <!-- <h3>

                                        </h3> -->
                                    </label>

                                    <v-divider></v-divider>
                                </v-col>

                                <v-row style="margin:auto;margin-top:-15px">

                                    <v-expand-transition>
                                        <div v-show="showPE">
                                            <v-btn text class="capitalize" v-if="requirement.CpnStatus == 4" x-small color="info" @click="saveClaim()">
                                                <v-icon>mdi-content-save</v-icon>Grabar
                                            </v-btn>
                                            <v-row style="margin:auto">
                                                <v-col cols="4" class="s-col-text" v-if="isEditable">
                                                    <s-select-definition add :def="1200" label="Tipo Pretención" v-model="itemClaim.TypePret"></s-select-definition>
                                                </v-col>
                                                <v-col cols="4" class="s-col-text" v-if="isEditable">
                                                    <s-select-definition :def="1129" label="Moneda" v-model="itemClaim.TypeCurrency"></s-select-definition>
                                                </v-col>
                                                <v-col cols="4" class="s-col-text" v-if="isEditable">
                                                    <s-text decimal label="Monto" v-model="itemClaim.CpcTotal" ref="CpcTotal"></s-text>
                                                </v-col>

                                                <v-col cols="12" class="s-col-text" v-if="isEditable">
                                                    <s-text label="Comentario" v-model="itemClaim.CpcComments"></s-text>
                                                </v-col>
                                            </v-row>

                                            <!-- <v-row style="margin:auto;margin-top:-15px">

                                            </v-row> -->

                                        </div>
                                    </v-expand-transition>

                                    <v-col cols="12" class="s-col-text">
                                        <v-card outlined>
                                            <v-data-table class="elevation-0 scroll table table-hover table-condensed" :items-per-page="-1" disable-sort hide-default-footer dense :headers="headersClaim" :items="requirement.listClaim">
                                                <template v-slot:item.SecCreate="{ item }">
                                                    {{$moment(item.SecCreate).format($const.FormatDateTimeDB)}}
                                                </template>
                                                <template v-if="requirement.CpnStatus == 4" v-slot:item.delete="{ item }">
                                                    <!-- <a v-if="item.UsrCreateID == userID" style="color:red" @click="deleteOP(item, 4)">Eliminar</a> -->
                                                    <v-icon v-if="item.UsrCreateID == userID" style="font-size:16px; color:red" @click="deleteOP(item, 4)"> mdi-minus-circle-outline</v-icon>
                                                </template>
                                                <template v-slot:item.CpcTotal="{ item }">

                                                    {{
                            item.CpcTotal >= 1000
                              ? item.CpcTotal.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : item.CpcTotal > 0 ? item.CpcTotal.toFixed(2) : 0
                          }}
                                                </template>
                                            </v-data-table>
                                        </v-card>
                                    </v-col>

                                </v-row>
                                <v-divider style="margin-top:0px; margin-bottom: 0px" horizontal></v-divider>
                                <!-- <v-divider style="margin-top:0px; margin-bottom: 0px" horizontal></v-divider>
 -->
                            </v-col>

                            <v-col cols="12" style="margin-top:-25px">
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                    <label>
                                        <h3>Detalle de Gasto.
                                            <v-btn icon elevation="0" @click="showDG = !showDG;showHP=false;showPE=false;showPR=false" rounded :color="'info'" x-small>
                                                <v-icon>{{ showDG ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                            </v-btn>
                                        </h3>
                                    </label>

                                    <v-divider></v-divider>
                                </v-col>

                                <v-row style="margin:auto;margin-top:-15px">

                                    <v-expand-transition>
                                        <div v-show="showDG">
                                            <v-btn text class="capitalize" v-if="requirement.CpnStatus == 4" x-small color="info" @click="saveBills()">
                                                <v-icon>mdi-content-save</v-icon>Grabar
                                            </v-btn>
                                            <v-row style="margin:auto">
                                                <v-col cols="6" lg="4" md="4" class="s-col-text" v-if="isEditable">
                                                    <s-select-definition :def="1206" label="Tipo G." v-model="itemBills.TypeBills"></s-select-definition>
                                                </v-col>
                                                <v-col cols="6" lg="4" md="4" class="s-col-text" v-if="isEditable">
                                                    <s-select-definition :def="1129" label="Moneda" v-model="itemBills.TypeCurrency"></s-select-definition>
                                                </v-col>
                                                <v-col cols="6" lg="4" md="4" class="s-col-text" v-if="isEditable">

                                                    <s-text label="Monto" decimal v-model="itemBills.CpbTotal" ref="CpbTotal"></s-text>
                                                </v-col>

                                                <v-col cols="12" class="s-col-text" v-if="isEditable">
                                                    <s-text label="Detalle" v-model="itemBills.CpbComments"></s-text>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-expand-transition>

                                    <v-col lg="12" cols="12" class="s-col-text">
                                        <v-card outlined>
                                            <v-data-table class="elevation-0 scroll table table-hover table-condensed" :items-per-page="-1" disable-sort hide-default-footer dense :headers="headersBills" :items="requirement.listBills">
                                                <template v-slot:item.SecCreate="{ item }">
                                                    {{$moment(item.SecCreate).format($const.FormatDateTimeDB)}}
                                                </template>
                                                <template v-if="requirement.CpnStatus == 4" v-slot:item.delete="{ item }">
                                                    <!--  <a v-if="item.UsrCreateID == userID" style="color:red" @click="deleteOP(item, 3)">Eliminar</a> -->
                                                    <v-icon v-if="item.UsrCreateID == userID" style="font-size:16px; color:red" @click="deleteOP(item, 3)"> mdi-minus-circle-outline</v-icon>
                                                </template>
                                                <template v-slot:item.CpbTotal="{ item }">

                                                    {{
                            item.CpbTotal >= 1000
                              ? item.CpbTotal.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : item.CpbTotal  > 0 ? item.CpbTotal.toFixed(2) : 0
                          }}
                                                </template>
                                                <template v-slot:item.CpbTotalDol="{ item }">

                                                    {{
                            item.CpbTotalDol >= 1000
                              ? item.CpbTotalDol.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : item.CpbTotalDol  > 0 ? item.CpbTotalDol.toFixed(2) : 0
                          }}
                                                </template>
                                            </v-data-table>
                                        </v-card>
                                    </v-col>

                                </v-row>
                                <v-divider style="margin-top:0px; margin-bottom: 0px" horizontal></v-divider>
                                <!-- <v-divider style="margin-top:0px; margin-bottom: 0px" horizontal></v-divider>
 -->
                            </v-col>

                            <!-- <v-col cols="6">

                                <v-row style="margin:auto;margin-top:-15px">

                                </v-row>

                            </v-col> -->

                        </v-row>

                        <v-divider style="margin-top:10px; margin-bottom: 0px" horizontal></v-divider>
                        <!-- <v-divider style="margin-top:0px; margin-bottom: 0px" horizontal></v-divider> -->
                    </v-col>

                    <v-col lg="12" md="12" cols="12">

                        <v-divider class="mt-3"></v-divider>
                        <v-divider class="mt-3"></v-divider>

                        <v-row style="margin-top:-40px">

                            <v-divider></v-divider>

                            <v-col cols="12">
                                <label>
                                    <h3>Detalle de Caso </h3>
                                </label>
                                <v-divider></v-divider>
                            </v-col>

                            <v-col cols="12">
                                <v-row style="margin:auto">
                                    <v-col cols="3" style="padding-top: 0px; padding-bottom: 0px;margin-top:-12px">
                                        <s-date :disabled="!isEditable" label="Fecha Inicio Proceso" :value="requirement.CpnDate" v-model="requirement.CpnDate" />
                                    </v-col>

                                    <v-col cols="3" class="s-col-form" style="padding-top: 0px; padding-bottom: 0px;margin-top:-12px">
                                        <s-text :readonly="!isEditable" label="Nro Expediente" v-model="requirement.CpnFileNumber" ref="CpnFileNumber"></s-text>
                                    </v-col>

                                    <!-- solamnete cuando es penal -->
                                    <v-col cols="3" class="s-col-form" style="padding-top: 0px; padding-bottom: 0px;margin-top:-12px" v-if="requirement.TypeComplaint !== 3">
                                        <s-text :readonly="!isEditable" label="Nro. Carpeta Fiscal" v-model="requirement.CpnFiscalFolder" ref="CpnFiscalFolder"></s-text>
                                    </v-col>

                                    <v-col cols="3" class="s-col-form" style="padding-top: 0px; padding-bottom: 0px;margin-top:-12px">
                                        <s-select-definition :readonly="!isEditable" add full autocomplete :def="1195" label="Dependencia Adm/Judicial" v-model="requirement.TypeCourt" />
                                    </v-col>
                                    <v-col cols="3" class="s-col-form" style="padding-top: 0px; padding-bottom: 0px;margin-top:-7px" v-if="requirement.TypeComplaint !==3">
                                        <s-select-definition :readonly="!isEditable" add full autocomplete :def="1204" label="Juez/Especialista" v-model="requirement.TypeSpecialistJudge" />
                                    </v-col>

                                </v-row>
                            </v-col>

                            <v-col cols="12" style="margin-top:-15px">

                                <v-row style="margin:auto">
                                    <v-col cols="6" class="s-col-form" style="padding-top: 0px; padding-bottom: 0px;margin-top:-7px" v-if="requirement.TypeComplaint ==3">
                                        <s-textarea :readonly="!isEditable" label="Posibles Contingencias" v-model="requirement.CpnPossibleContingencies" ref="CpnPossibleContingencies"></s-textarea>
                                    </v-col>
                                    <v-col cols="12" lg="6" md="6" class="s-col-form" style="padding-top: 0px; padding-bottom: 0px;margin-top:-7px" v-if="requirement.TypeComplaint ==3 && !isEditablePnl">
                                        <v-card outlined>
                                            <v-row style="margin:auto;margin-top:5px">

                                                <v-row style="margin:auto" v-if="isEditable">
                                                    <v-col cols="12" class="s-col-text">
                                                        <s-textarea label="Imputaciones" v-model="itemAllegations.AlgDescription" ref="AlgDescription"></s-textarea>
                                                    </v-col>
                                                    <v-btn text class="capitalize" v-if="requirement.CpnStatus == 4" x-small color="info" @click="saveAllegations()">
                                                        <v-icon>mdi-content-save</v-icon>Grabar
                                                    </v-btn>
                                                </v-row>
                                                <v-row v-else style="margin:auto">
                                                    <h3 class="ml-3">Imputaciones</h3>
                                                </v-row>

                                                <v-col lg="12" cols="12" class="s-col-text">
                                                    <v-card outlined>
                                                        <v-data-table class="elevation-0 scroll table table-hover table-condensed" :items-per-page="-1" disable-sort hide-default-footer dense :headers="headersAllegations" :items="requirement.listAllegations">
                                                            <template v-slot:item.SecCreate="{ item }">
                                                                {{$moment(item.SecCreate).format($const.FormatDateTimeDB)}}
                                                            </template>
                                                            <template v-if="requirement.CpnStatus == 4" v-slot:item.delete="{ item }">
                                                                <!-- <a v-if="item.UsrCreateID == userID" style="color:red" @click="deleteOP(item, 6)">Eliminar</a> -->
                                                                <v-icon v-if="item.UsrCreateID == userID" style="font-size:16px; color:red" @click="deleteOP(item, 6)"> mdi-minus-circle-outline</v-icon>
                                                            </template>
                                                        </v-data-table>
                                                    </v-card>
                                                </v-col>

                                            </v-row>
                                        </v-card>

                                        <!-- <s-text :disabled="!isEditable" label="Imputaciones" v-model="requirement.CpnAccusations" ref="CpnAccusations"></s-text> -->
                                    </v-col>
                                </v-row>
                            </v-col>

                            <v-col cols="12" style="margin-top:-25px">
                                <v-row style="margin:auto">

                                    <v-col cols="6" class="s-col-form">
                                        <s-textarea :label="requirement.TypeComplaint == 1 ? 'Delito de la denuncia': requirement.TypeComplaint == 2 ? 'Materia de demanda' : requirement.TypeComplaint == 3 ? 'Medidad correctivas' : ''" v-model="requirement.CpnSummary" ref="CpnSummary"></s-textarea>
                                    </v-col>
                                    <v-col cols="6" class="s-col-form">
                                        <s-textarea :label="requirement.TypeComplaint == 1 ? 'Hechos de la denuncia': requirement.TypeComplaint == 2 ? 'Hechos de la demanda' : requirement.TypeComplaint == 3 ? 'Hechos de la denuncia' : ''" v-model="requirement.CpnFactsTheLawsuit" ref="CpnFactsTheLawsuit"></s-textarea>
                                    </v-col>
                                    <v-col cols="12" class="s-col-form" style="padding-top: 0px; padding-bottom: 0px;margin-top:0px">
                                        <s-text :readonly="!isEditable" label="Lote" v-model="requirement.CpnLot" ref="CpnLot"></s-text>
                                    </v-col>
                                </v-row>
                            </v-col>

                            <v-col cols="12" style="margin-top:-14px" v-if="requirement.TypeComplaint == 3">
                                <v-divider></v-divider>
                                <label>
                                    <h3>Admisorio </h3>
                                </label>
                                <v-divider></v-divider>
                            </v-col>

                            <v-col cols="12" v-if="requirement.TypeComplaint == 3">
                                <v-row style="margin:auto">
                                    <v-col cols="4" style="padding-top: 0px; padding-bottom: 0px;margin-top:-12px">
                                        <s-date :disabled="!isEditable" label="Fecha" :value="requirement.CpnAdmissionDate" v-model="requirement.CpnAdmissionDate" />
                                    </v-col>

                                    <v-col cols="8" class="s-col-form" style="padding-top: 0px; padding-bottom: 0px;margin-top:-12px">
                                        <s-textarea label="Detalle" v-model="requirement.CpnAdmissionDetail" ref="CpnAdmissionDetail"></s-textarea>
                                    </v-col>

                                </v-row>
                            </v-col>

                            <!-- <v-row>

                                <v-col cols="6">
                                    <v-col cols="12">
                                        <v-divider></v-divider>
                                        <label>
                                            <h3>{{requirement.TypeComplaint == 2 ? 'Datos del Demandante(s)' : 'Datos del Denunciante(s)'}} </h3>
                                        </label>
                                        <v-divider></v-divider>
                                    </v-col>
                                    <v-col cols="12" class="s-col-form" style="margin-top:-12px">
                                        <s-textarea label="Nombre(s)" v-model="requirement.CpnNameComplaint" ref="CpnNameComplaint"></s-textarea>
                                    </v-col>
                                </v-col>
                                <v-divider vertical></v-divider>
                                <v-col cols="6">
                                    <v-col cols="12">
                                        <v-divider></v-divider>
                                        <label>
                                            <h3>{{requirement.TypeComplaint == 2 ? 'Datos del Demandado(s)' : 'Datos del Denunciado(s)'}} </h3>
                                        </label>
                                        <v-divider></v-divider>
                                    </v-col>
                                    <v-col cols="12" class="s-col-form" style="margin-top:-12px">
                                        <s-textarea label="Denunciado(s)" v-model="requirement.CpnNamePersonaDenounced" ref="CpnNamePersonaDenounced"></s-textarea>
                                    </v-col>
                                </v-col>

                            </v-row> -->

                            <!--  <v-col cols="12">
                                <v-divider></v-divider>
                                <v-divider></v-divider>
                            </v-col> -->

                        </v-row>

                        <!-- <v-divider style="margin-top:10px; margin-bottom: 0px" horizontal></v-divider> -->

                        <!-- <v-divider style="margin-top:0px; margin-bottom: 0px" horizontal></v-divider> -->
                    </v-col>

                    <v-col cols="12" v-if="!isEditablePnl">
                        <v-divider></v-divider>

                        <v-row>

                            <!-- <v-col lg="6" cols="6" class="s-col-text">
                                <v-row>
                                    <v-col cols="12">
                                        <v-divider></v-divider>
                                        <label>
                                            <h3>Pretencion Ecónomica. <v-btn class="capitalize" v-if="requirement.CpnStatus == 4" x-small text color="#5175af" @click="saveClaim()">
                                                    <v-icon>mdi-content-save</v-icon>Grabar
                                                </v-btn>
                                            </h3>
                                        </label>

                                        <v-divider></v-divider>
                                    </v-col>

                                    <v-row style="margin:auto;margin-top:-15px">
                                        <v-col cols="4" class="s-col-text" v-if="isEditable">
                                            <s-select-definition add :def="1200" label="Tipo Pretención" v-model="itemClaim.TypePret"></s-select-definition>
                                        </v-col>
                                        <v-col cols="4" class="s-col-text" v-if="isEditable">
                                            <s-select-definition :def="1129" label="Moneda" v-model="itemClaim.TypeCurrency"></s-select-definition>
                                        </v-col>
                                        <v-col cols="4" class="s-col-text" v-if="isEditable">
                                            <s-text decimal label="Monto" v-model="itemClaim.CpcTotal" ref="CpcTotal"></s-text>
                                        </v-col>

                                        <v-col cols="12" class="s-col-text" v-if="isEditable">
                                            <s-text label="Comentario" v-model="itemClaim.CpcComments"></s-text>
                                        </v-col>

                                        <v-col cols="12" class="s-col-text">
                                            <v-card outlined>
                                                <v-data-table class="elevation-0 scroll table table-hover table-condensed" :items-per-page="-1" disable-sort hide-default-footer dense :headers="headersClaim" :items="requirement.listClaim">
                                                    <template v-slot:item.SecCreate="{ item }">
                                                        {{$moment(item.SecCreate).format($const.FormatDateTimeDB)}}
                                                    </template>
                                                    <template v-if="requirement.CpnStatus == 4" v-slot:item.delete="{ item }">
                                                        <a v-if="item.UsrCreateID == userID" style="color:red" @click="deleteOP(item, 4)">Eliminar</a>
                                                    </template>
                                                    <template v-slot:item.CpcTotal="{ item }">

                                                        {{
                            item.CpcTotal >= 1000
                              ? item.CpcTotal.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : item.CpcTotal > 0 ? item.CpcTotal.toFixed(2) : 0
                          }}
                                                    </template>
                                                </v-data-table>
                                            </v-card>
                                        </v-col>
                                    </v-row>

                                </v-row>

                            </v-col> -->

                            <v-divider vertical></v-divider>

                            <!-- <v-col cols="6">
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                    <label>
                                        <h3>Detalle de Gasto. <v-btn class="capitalize" v-if="requirement.CpnStatus == 4" x-small text color="#5175af" @click="saveBills()">
                                                <v-icon>mdi-content-save</v-icon>Grabar
                                            </v-btn>
                                        </h3>
                                    </label>
                                    <v-divider></v-divider>
                                </v-col>

                                <v-row style="margin:auto;margin-top:-15px">
                                    <v-col cols="6" class="s-col-text" v-if="isEditable">
                                        <s-select-definition :def="1129" label="Moneda" v-model="itemBills.TypeCurrency"></s-select-definition>
                                    </v-col>
                                    <v-col cols="6" class="s-col-text" v-if="isEditable">

                                        <s-text label="Monto" decimal v-model="itemBills.CpbTotal" ref="CpbTotal"></s-text>
                                    </v-col>

                                    <v-col cols="12" class="s-col-text" v-if="isEditable">
                                        <s-text label="Detalle" v-model="itemBills.CpbComments"></s-text>
                                    </v-col>
                                    <v-col lg="12" cols="12" class="s-col-text">
                                        <v-card outlined>
                                            <v-data-table class="elevation-0 scroll table table-hover table-condensed" :items-per-page="-1" disable-sort hide-default-footer dense :headers="headersBills" :items="requirement.listBills">
                                                <template v-slot:item.SecCreate="{ item }">
                                                    {{$moment(item.SecCreate).format($const.FormatDateTimeDB)}}
                                                </template>
                                                <template v-if="requirement.CpnStatus == 4" v-slot:item.delete="{ item }">
                                                    <a v-if="item.UsrCreateID == userID" style="color:red" @click="deleteOP(item, 3)">Eliminar</a>
                                                </template>
                                                <template v-slot:item.CpbTotal="{ item }">

                                                    {{
                            item.CpbTotal >= 1000
                              ? item.CpbTotal.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : item.CpbTotal  > 0 ? item.CpbTotal.toFixed(2) : 0
                          }}
                                                </template>
                                            </v-data-table>
                                        </v-card>
                                    </v-col>

                                </v-row>

                            </v-col> -->
                        </v-row>

                    </v-col>
                </v-row>
            </v-col>
        </v-card>
    </v-card>

</div>
</template>

<script>
//Services
import _sComplaints from "@/services/Legal/LglComplaintsService";
import _sRequirementConfig from "@/services/Legal/LglRequirementconfig";
import _sGeneral from "@/services/HelperService.js";
import VContract from '../vContract.vue';

export default {
    components: {
        VContract

    },
    props: {
        value: {
            default: 1,
            type: Number
        },
    },

    data() {
        return {
            processing: false,
            messageProcessing: "",
            requirement: {
                CpnDate: null,
                CpnID: null,
                CpnStatus: null
            },
            itemsAttach: [],
            listAttach: [],
            headersAttach: [{
                    text: "Decripción",
                    value: "RqdDescription"
                },
                {
                    text: "Archivo",
                    value: "RqdAttach"
                },
                {
                    text: "Creado P.",
                    value: "NtpName"
                },
                {
                    text: "F. C.",
                    value: "SecCreate"
                },
                {
                    text: "DEL",
                    value: "delete",
                    width: 50
                },

            ],
            itemBills: {},
            itemRecord: {},
            itemClaim: {},
            itemProvition: {},
            itemAllegations: {},
            headersProvition: [{
                    text: "F. Registro",
                    value: "SecCreate",
                    width: 100
                },
                {
                    text: "Categoria",
                    value: "TypeCategoryName"
                },
                {
                    text: "Moneda",
                    value: "TypeCurrencyName"
                },
                {
                    text: "Total",
                    value: "PvtTotal"
                },
                {
                    text: "Comentario",
                    value: "PvtComments"
                },
                {
                    text: "DEL",
                    value: "delete",
                    width: 50
                },
            ],
            headersBills: [{
                    text: "F. Registro",
                    value: "SecCreate",
                    width: 100
                },
                {
                    text: "Tipo G.",
                    value: "TypeBillsName"
                },
                {
                    text: "Moneda",
                    value: "TypeCurrencyName"
                },
                {
                    text: "Total",
                    value: "CpbTotal"
                },
                {
                    text: "Total ($)",
                    value: "CpbTotalDol"
                },
                {
                    text: "Comentario",
                    value: "CpbComments"
                },
                {
                    text: "DEL",
                    value: "delete",
                    width: 50
                },
            ],
            headersRecord: [{
                    text: "F. Registro",
                    value: "SecCreate",
                    width: 100
                },
                {
                    text: "Estado",
                    value: "TypeStateName"
                },
                {
                    text: "Tarea a realizar",
                    value: "CprTasktoPerform"
                },
                {
                    text: "Comentario",
                    value: "CprComments"
                },
                {
                    text: "DEL",
                    value: "delete",
                    width: 50
                },
            ],
            headersClaim: [{
                    text: "F. Registro",
                    value: "SecCreate",
                    width: 100
                },
                {
                    text: "Tipo P.",
                    value: "TypePretName"
                },
                {
                    text: "Moneda",
                    value: "TypeCurrencyName"
                },
                {
                    text: "Total",
                    value: "CpcTotal"
                },
                {
                    text: "Total ($)",
                    value: "CpcTotalDol"
                },

                {
                    text: "Comentario",
                    value: "CpcComments"
                },
                {
                    text: "DEL",
                    value: "delete",
                    width: 50
                },
            ],

            headersAllegations: [{
                    text: "F. Registro",
                    value: "SecCreate",
                    width: 100
                },
                {
                    text: "Imputaciòn",
                    value: "AlgDescription"
                },
                {
                    text: "DEL",
                    value: "delete",
                    width: 50
                },
            ],
            descriptionAttach: null,
            dialog: false,
            objPro: null,
            //nameImg: "enproceso.png",
            // rutaImg: "@/assets/" + this.nameImg
            rutaImg: "",
            showHP: false,
            showPR: false,
            showPE: false,
            showDG: false,
        }
    },
    watch: {
        isEditablePnl() {
            this.processing = false;
        },
    },

    computed: {
        user() {
            return this.$fun.getUserInfo();
        },
        userID() {
            return this.$fun.getUserID();
        },
        isEditable() {
            return this.requirement.CpnStatus == 4;
        },
        isEditablePnl() {
            return this.requirement.CpnID == null;
        },
    },

    created() {
        if (this.value != null) {
            this.initialize();
        }

        this.getConfigAttach();
    },
    mounted() {
        if (this.value != null) {
            this.processing = true;
        }

        //this.rutaImg = "@/assets/" + this.nameImg
    },
    methods: {

        initialize() {
            _sComplaints.byID({
                CpnID: this.value
            }, this.$fun.getUserID()).then((r) => {
                r.data.TotalResumen = r.data.ClaimTotal - r.data.BillsTotal
                r.data.listAttach.forEach(element => {
                    element.Attach = {
                        name: element.RqdAttach
                    };
                    element.SecCreate = this.$moment(element.SecCreate).format(this.$const.FormatDateTimeDB);
                });

                this.rutaImg = r.data.CpnStatusRute

                this.requirement = r.data;

                console.log("al levantar", this.requirement, this.rutaImg)
            });
        },

        //Descargar Archivos
        openFile(url) {
            console.log(url)
            if (url == null) return;
            if (url.name == null) return

            _sGeneral.download(url.name, this.$fun.getUserID(), 8).then((r) => {
                this.$fun.download(r.data, url.name);
            });
        },

        getConfigAttach() {
            _sRequirementConfig.list(103, this.$fun.getUserID()).then(resp => {
                this.itemsAttach = resp.data

            })
        },

        uploadFile() {

            this.processing = false

            if (this.descriptionAttach == null || this.descriptionAttach.length <= 0) {
                this.$refs.descriptionAttach.error("Descripcion de archivo.")
                return
            }

            if (this.$fun.isValidExtension(this.itemsAttach)) {
                return
            }

            if (this.$fun.isValidTamanio(this.itemsAttach)) {
                return
            }

            //Inicio Cargar archivos en servidor
            let files = [];

            for (let index = 0; index < this.itemsAttach.length; index++) {
                if (this.itemsAttach[index].RqdAttach !== null) {
                    files.push({
                        RqdID: this.itemsAttach[index].RqdID,
                        RqdAttach: this.itemsAttach[index].RqdAttach
                    })
                }
            }

            if (files.length == 0) {
                this.$fun.alert("No existe archivo para subir", "warning")
                return
            }

            var formData = new FormData();
            files.forEach((element) => {
                formData.append(element.RqdID, element.RqdAttach);
            });

            this.$fun.alert("¿Seguro de subir Archivo?", "question", false).then(val => {
                if (val.value) {

                    this.messageProcessing = ""
                    this.processing = true

                    _sGeneral.uploadfilelegal("123", this.$fun.getUserID(), formData, 2).then(resp => {
                            if (resp.status == 200) {

                                this.requirement.itemsAttach = resp.data
                                this.requirement.itemsAttach.forEach(element => {
                                    element.RqdDescription = this.descriptionAttach
                                    element.CpnID = this.requirement.CpnID
                                    element.UsrCreateID = this.$fun.getUserID()
                                    element.SecStatus = 1
                                });

                                if (this.requirement.itemsAttach.length > 0) {

                                    _sComplaints.savefiles(this.requirement.itemsAttach, this.$fun.getUserID()).then((r) => {
                                            if (r.status == 200) {
                                                this.initialize()
                                                this.messageProcessing = ""
                                                this.processing = false
                                            }
                                        },
                                        (e) => {
                                            this.messageProcessing = e.response.data.Message;
                                            this.processing = false;

                                        });
                                }
                            }
                        },
                        (e) => {});
                }
            })

        },

        save() {

            if (this.requirement.CpnFileNumber == null || this.requirement.CpnFileNumber.length <= 0) {

                this.$refs.CpnFileNumber.error("Ingrese Nro. de Expediente")
                return
            }

            if (this.requirement.CpnSummary == null || this.requirement.CpnSummary.length <= 0) {

                this.$refs.CpnSummary.error("Ingrse Descripcion de Delito/Materia de la denuncia.")
                return
            }

            if (this.requirement.CpnNameComplaint == null || this.requirement.CpnNameComplaint.length <= 0) {

                this.$refs.CpnNameComplaint.error("Datos del denunciante")
                return
            }

            if (this.requirement.CpnNamePersonaDenounced == null || this.requirement.CpnNamePersonaDenounced.length <= 0) {

                this.$refs.CpnNamePersonaDenounced.error("Datos de denunciado")
                return
            }

            if (this.$fun.isValidExtension(this.itemsAttach)) {
                return
            }

            if (this.$fun.isValidTamanio(this.itemsAttach)) {
                return
            }

            this.requirement.UsrCreateID = this.$fun.getUserID();
            this.requirement.UsrUpdateID = this.$fun.getUserID();
            this.requirement.UsrID = this.$fun.getUserID();
            this.requirement.SecStatus = 1;

            this.requirement.itemsAttach = []

            this.processing = false;

            console.log("save", this.requirement)

            this.$fun.alert("Seguro de continuar con la Solicitud?", "question", false).then((r) => {
                if (r.value) {

                    this.messageProcessing = ""
                    this.processing = true
                    //Inicio Cargar archivos en servidor
                    let files = [];

                    for (let index = 0; index < this.itemsAttach.length; index++) {
                        if (this.itemsAttach[index].RqdAttach !== null) {
                            files.push({
                                RqdID: this.itemsAttach[index].RqdID,
                                RqdAttach: this.itemsAttach[index].RqdAttach,
                            })
                        }
                    }

                    var formData = new FormData();
                    files.forEach((element) => {
                        formData.append(element.RqdID, element.RqdAttach);
                    });

                    _sGeneral.uploadfilelegal("123", this.$fun.getUserID(), formData, 2).then(resp => {
                        if (resp.status == 200) {
                            this.requirement.itemsAttach = resp.data
                            console.log(this.requirement)

                            if (this.requirement.itemsAttach.length > 0 && this.requirement.itemsAttach !== "No cargo ningún archivo")
                                this.requirement.itemsAttach.forEach(element => {
                                    element.TypeDocument = 1
                                    element.UsrCreateID = this.$fun.getUserID()
                                });

                            _sComplaints.save(this.requirement, this.$fun.getUserID()).then((r) => {
                                    this.$fun.alert("Guardado Correctamente", "success");
                                    this.$emit("close");
                                    this.messageProcessing = ""
                                    this.processing = false

                                },
                                (e) => {
                                    this.messageProcessing = e.response.data.Message;
                                    this.processing = false;
                                });

                        }
                    })
                }
            });
        },

        saveRecord() {

            this.itemRecord.CpnID = this.requirement.CpnID
            this.itemRecord.UsrCreateID = this.$fun.getUserID()
            this.itemRecord.SecStatus = 1

            if (this.itemRecord.CprTasktoPerform == null || this.itemRecord.CprTasktoPerform.length <= 0) {
                this.$refs.CprTasktoPerform.error("Tarea a realizar")
                return
            }

            console.log("save record", this.itemRecord)

            this.processing = false
            this.$fun.alert("¿Seguro de grabar Proceso ?", "question", false).then(val => {
                if (val.value) {

                    this.messageProcessing = ""
                    this.processing = true

                    _sComplaints.saveRecord(this.itemRecord, this.$fun.getUserID()).then((r) => {
                            if (r.status == 200) {
                                this.initialize()
                                this.messageProcessing = ""
                                this.processing = false
                                this.showHP = false
                                this.itemRecord.CprTasktoPerform = null
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = false;

                        });

                }
            })

        },

        saveBills() {

            this.itemBills.CpnID = this.requirement.CpnID
            this.itemBills.UsrCreateID = this.$fun.getUserID()
            this.itemBills.SecStatus = 1

            if (this.itemBills.CpbTotal <= 0) {
                this.$refs.CpbTotal.error("Monto debe ser mayor a 0")
                return
            }

            console.log("save bills", this.itemBills)

            this.processing = false
            this.$fun.alert("¿Seguro de grabar Gasto?", "question", false).then(val => {
                if (val.value) {
                    this.messageProcessing = ""
                    this.processing = true

                    _sComplaints.saveBills(this.itemBills, this.$fun.getUserID()).then((r) => {
                            if (r.status == 200) {
                                this.initialize()
                                this.messageProcessing = ""
                                this.processing = false
                                this.showDG = false
                                this.itemBills.CpbTotal = 0
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = false;

                        });
                }
            })

        },

        saveClaim() {

            this.itemClaim.CpnID = this.requirement.CpnID
            this.itemClaim.UsrCreateID = this.$fun.getUserID()
            this.itemClaim.SecStatus = 1

            if (this.itemClaim.CpcTotal <= 0) {
                this.$refs.CpcTotal.error("Monto debe ser mayor a 0")
                return
            }

            this.processing = false
            this.$fun.alert("¿Seguro de grabar Pretención Económica ?", "question", false).then(val => {
                if (val.value) {
                    this.messageProcessing = ""
                    this.processing = true

                    _sComplaints.saveClaim(this.itemClaim, this.$fun.getUserID()).then((r) => {
                            if (r.status == 200) {
                                this.initialize()
                                this.messageProcessing = ""
                                this.processing = false
                                this.showPE = false
                                this.itemClaim.CpcTotal = 0
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = false;

                        });
                }
            })

        },

        saveProvition() {

            this.itemProvition.CpnID = this.requirement.CpnID
            this.itemProvition.UsrCreateID = this.$fun.getUserID()
            this.itemProvition.SecStatus = 1

            if (this.itemProvition.PvtTotal <= 0 && this.itemProvition.TypeCategory !== 3) {
                this.$refs.PvtTotal.error("Monto debe ser mayor a 0")
                return
            }

            this.processing = false
            this.$fun.alert("¿Seguro de grabar ProvisiÓn ?", "question", false).then(val => {
                if (val.value) {
                    this.messageProcessing = ""
                    this.processing = true

                    _sComplaints.saveProvition(this.itemProvition, this.$fun.getUserID()).then((r) => {
                            if (r.status == 200) {
                                this.initialize()
                                this.messageProcessing = ""
                                this.processing = false
                                this.showPR = false
                                this.itemProvition.PvtTotal = 0
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = false;

                        });
                }
            })

        },

        saveAllegations() {

            this.itemAllegations.CpnID = this.requirement.CpnID
            this.itemAllegations.UsrCreateID = this.$fun.getUserID()
            this.itemAllegations.SecStatus = 1

            if (this.itemAllegations.AlgDescription == null || this.itemAllegations.AlgDescription.length <= 0) {
                this.$refs.AlgDescription.error("Ingrese descripción")
                return
            }

            this.processing = false
            this.$fun.alert("¿Seguro de grabar Imputación?", "question", false).then(val => {
                if (val.value) {
                    this.messageProcessing = ""
                    this.processing = true

                    _sComplaints.saveAllegations(this.itemAllegations, this.$fun.getUserID()).then((r) => {
                            if (r.status == 200) {
                                this.initialize()
                                this.messageProcessing = ""
                                this.processing = false
                                this.itemAllegations = {}
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = false;

                        });
                }
            })

        },

        deleteOP(item, op) {
            console.log(item, op)

            item.SecStatus = 0
            item.UsrUpdateID = this.$fun.getUserID()

            this.messageProcessing = ""
            this.processing = true
            if (op == 1) {

                _sComplaints.savefiles([item], this.$fun.getUserID()).then((r) => {
                        if (r.status == 200) {
                            this.initialize()
                            this.messageProcessing = ""
                            this.processing = false

                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = false;

                    });
            }

            if (op == 2) {

                _sComplaints.saveRecord(item, this.$fun.getUserID()).then((r) => {
                        if (r.status == 200) {
                            this.initialize()
                            this.messageProcessing = ""
                            this.processing = false
                            this.showHP = false
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = false;

                    });
            }

            if (op == 3) {

                _sComplaints.saveBills(item, this.$fun.getUserID()).then((r) => {
                        if (r.status == 200) {
                            this.initialize()
                            this.messageProcessing = ""
                            this.processing = false
                            this.showDG = false
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = false;

                    });
            }

            if (op == 4) {

                _sComplaints.saveClaim(item, this.$fun.getUserID()).then((r) => {
                        if (r.status == 200) {
                            this.initialize()
                            this.messageProcessing = ""
                            this.processing = false
                            this.showPE = false
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = false;

                    });
            }

            if (op == 5) {

                _sComplaints.saveProvition(item, this.$fun.getUserID()).then((r) => {
                        if (r.status == 200) {
                            this.initialize()
                            this.messageProcessing = ""
                            this.processing = false
                            this.showPR = false
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = false;

                    });
            }

            if (op == 6) {

                _sComplaints.saveAllegations(item, this.$fun.getUserID()).then((r) => {
                        if (r.status == 200) {
                            this.initialize()
                            this.messageProcessing = ""
                            this.processing = false
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = false;

                    });
            }
        },

        closeProccess() {

            this.requirement.CpnStatus = this.objPro.DedValue
            this.requirement.UsrUpdateID = this.$fun.getUserID()

            console.log("closeProccess", this.requirement)

            this.$fun.alert("Seguro de terminar proceso?", "question", false).then((r) => {
                if (r.value) {

                    this.messageProcessing = ""
                    this.processing = true

                    _sComplaints.save(this.requirement, this.$fun.getUserID()).then((r) => {
                            this.$fun.alert("Proceso terminado Correctamente", "success");
                            this.$emit("close");
                            this.messageProcessing = ""
                            this.processing = false

                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = false;
                        });

                }

            })

            /* console.log(
                this.rutaImg,
                this.objPro,
            ) */
        }

    }

};
</script>
