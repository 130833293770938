import Service from "../Service";

const resource = "complaintslegal/";

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    byID(parameters, requestID) {
        return Service.post(resource + "byid", parameters, {
            params: { requestID: requestID },
        });
    },

    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },

    savefiles(obj, requestID) {
        return Service.post(resource + "savefiles", obj, {
            params: { requestID: requestID },
        });
    },

    saveRecord(obj, requestID) {
        return Service.post(resource + "saverecord", obj, {
            params: { requestID: requestID },
        });
    },

    saveBills(obj, requestID) {
        return Service.post(resource + "savebills", obj, {
            params: { requestID: requestID },
        });
    },
    saveClaim(obj, requestID) {
        return Service.post(resource + "savecalim", obj, {
            params: { requestID: requestID },
        });
    },

    saveProvition(obj, requestID) {
        return Service.post(resource + "saveprovition", obj, {
            params: { requestID: requestID },
        });
    },

    saveAllegations(obj, requestID) {
        return Service.post(resource + "saveallegations", obj, {
            params: { requestID: requestID },
        });
    },
};